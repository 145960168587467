import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ChannelRoutes } from "../../enums/routes.enum";
import { OverflowContent } from "../../models/overflow-content";
import { SingleSpaAssetsPipe } from "../../pipes/asset.pipe";
import { NavigateService } from "../../services/navigate.service";
import { StoreCdtService } from "../../services/store-cdt.service";

@Component({
  selector: "app-generic-modal",
  templateUrl: "./generic-modal.component.html",
  styleUrls: ["./generic-modal.component.scss"],
  standalone: true,
  imports: [SingleSpaAssetsPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GenericModalComponent implements OnChanges {
  private storeCdtService = inject(StoreCdtService);
  private navigateService = inject(NavigateService);
  private cdr = inject(ChangeDetectorRef);
  @Input() hideModal!: boolean;
  @Input() modalErrType!: string;

  modalOverflows: OverflowContent[];
  modalContent!: OverflowContent;

  constructor() {
    this.modalOverflows = this.storeCdtService.overflows.value.modalOverflows;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["modalErrType"] && changes["modalErrType"].currentValue) {
      this.validateOverflowType();
    }
  }

  /**
   * Método que valida el tipo de error que se debe mostrar en la modal
   */
  validateOverflowType(): void {
    this.modalOverflows.forEach((content) => {
      if (
        content.modalType &&
        content.modalType.toLowerCase() === this.modalErrType.toLowerCase()
      ) {
        this.modalContent = content;
        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Método que redirecciona al home de canales
   */
  modalRedirect(): void {
    this.hideModal = true;
    this.navigateService.navigate(ChannelRoutes.Home);
  }
}
