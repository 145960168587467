import { Inject, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MODULE_FEDERATION } from "../../../app/module-federation/injection.token";
import { navigateToUrl } from "single-spa";

@Injectable({
  providedIn: "root",
})
export class NavigateService {
  private readonly basePathModuleFederation = "bancadigital/abrir-cdt-digital";
  private readonly router = inject(Router);

  constructor(@Inject(MODULE_FEDERATION) private isModuleFederation: boolean) {}
  navigate(route: string) {
    if (this.isModuleFederation) {
      this.router.navigateByUrl(`${this.basePathModuleFederation}${route}`);
    } else {
      this.router.navigateByUrl(route);
    }
  }
}
