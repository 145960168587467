import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppRoutes } from "../shared/enums/routes.enum";
import { NavigateService } from "../shared/services/navigate.service";
import { UserDataService } from "../shared/services/user-data.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private userDataService: UserDataService,
    private navigateService: NavigateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.url[0].path === "otp" || route.url[0].path === "desborde") {
      return this.validateData();
    } else {
      return this.validateAuthData();
    }
  }

  /**
   * Método que valida si existe la información de cdt almacenada
   * @returns retorna un valor de false o true si permite la navegación a la página
   */
  validateData(): boolean {
    if (
      this.userDataService.creationCdt.amount &&
      this.userDataService.creationCdt.term
    ) {
      return true;
    } else {
      this.navigateService.navigate(AppRoutes.Simulator);
      return false;
    }
  }

  /**
   * Método que valida si existe la información de cdt y el token almacenados
   * @returns retorna un valor de false o true si permite la navegación a la página
   */
  validateAuthData(): boolean {
    if (this.validateData() && this.userDataService.customerData.accessToken) {
      return true;
    } else {
      this.navigateService.navigate(AppRoutes.Simulator);
      return false;
    }
  }
}
