import { Component } from "@angular/core";

@Component({
  selector: "app-empty-route",
  template: "",
  standalone: true,
})
export class EmptyRouteComponent {
  constructor() {
    history.replaceState(null, "", "/bancadigital");
  }
}
