export enum BasePath {
  Base = "/bancadigital/abrir-cdt-digital",
}

export enum AppRoutes {
  Simulator = "/simulador",
  Otp = "/otp",
  OtpStart = "/otp/inicio",
  Accounts = "/seleccion-cuentas",
  Configuration = "/configuracion",
  Tips = "/tips",
  Summary = "/resumen-producto",
}

export enum OverflowRoutes {
  overflow = "/desborde",

  GenerationAttemps = "intentos-generacion",
  MaxAttemptOTP = "maximo-intentos",
  ValidationAttemps = "intentos-validacion",
  SummaryTransaction = "resumen-transaccion",
}

export enum ChannelRoutes {
  Home = "../",
}
